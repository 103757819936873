.btn:focus {
    outline: none;
    box-shadow: none;
}

.py-10 {
    padding-top: 150pt;
    padding-bottom: 150pt;

    /* animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;  */
}

/* @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
} */